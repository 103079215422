import styles from './ProfileTemplate.module.css';
import {profileChangePassForm, profileDataForm} from '../../../../data/forms';
import Form from '../../../organisms/Form/Form';
import { useNavigate } from 'react-router';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';

export default function ProfileTemplate(props){

  const {changePassword, editProfile, setChangePassData, changePassData, setEditProfileData, editProfileData} = props;
  const {changePasswordStatus, setChangePasswordStatus} = useContext(AppContext)

  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1>Mi perfil</h1>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <h2 className={styles.title}>{profileDataForm.title}</h2>
          <Form 
            formFields={profileDataForm.formFields}
            button={{
              ...profileDataForm.button,
              handleClick: () => editProfile(editProfileData, navigate)
            }}
            setApiData={setEditProfileData}
            apiData={editProfileData}
          />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>{profileChangePassForm.title}</h2>
          <Form 
            formFields={profileChangePassForm.formFields}
            button={{
              ...profileChangePassForm.button,
              text:
                changePasswordStatus == 'success' ?
                "Contraseña modificada con éxito!" :
                changePasswordStatus == 'pending' ?
                "Modificando contraseña..." :
                "Modificar contraseña"
              ,
              style:
                changePasswordStatus == 'success' ?
                "success" :
                changePasswordStatus == 'pending' ?
                "disabled" :
                "primary"
              ,
              handleClick: () => 
              (changePasswordStatus == 'pending'|| changePasswordStatus == 'success') ? 
              undefined : 
              changePassword(changePassData, navigate)
            }}
            setApiData={setChangePassData}
            apiData={changePassData}
          />
        </div>
      </div>
    </div>
  );
}
  
  