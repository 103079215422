import RecoverPasswordEmail from "../pages/auth/RecoverPasswordEmail";
import RecoverPasswordCode from "../pages/auth/RecoverPasswordCode";
import RecoverPasswordSuccess from "../pages/auth/RecoverPasswordSuccess";
import RecoverPasswordNewPassword from "../pages/auth/RecoverPasswordNewPassword";
import SignIn from "../pages/auth/SignIn";
import dashboard from '../assets/icons/dashboard.svg';
import users from '../assets/icons/users.svg';
import cashflow from '../assets/icons/cash-flow.svg';
import restore from '../assets/icons/restore.svg';
import profile from '../assets/icons/profile.svg';
import Dashboard from "../pages/users/Dashboard";
import Movements from "../pages/users/Movements";
import AdminProfile from "../pages/admin/Profile";
import Profile from "../pages/users/Profile";
import Users from "../pages/admin/users/Users";
import AddUser from "../pages/admin/users/AddUser";
import EditUser from "../pages/admin/users/EditUser";
import CashFlow from "../pages/admin/CashFlow";
import documents from '../assets/icons/reports.svg';
import news from '../assets/icons/blog.svg';
import phone from '../assets/icons/wpp.svg';
import EditMovement from "../pages/admin/EditMovement";
import Activity from "../pages/users/Activity";
import { BLOG_URL, CONTACT_NUMBER } from "./variables";
import AddMovement from "../pages/admin/AddMovement";


export const onboardingRoutes = [
    {
        path:"/sign-in",
        label: "Iniciar sesión",
        component: <SignIn/>,
    },
    {
        path:"/recover-password/email",
        label: "Recuperar contraseña - Email",
        component: <RecoverPasswordEmail/>,
    },
    {
        path:"/recover-password/code",
        label: "Recuperar contraseña - Código",
        component: <RecoverPasswordCode/>,
    },
    {
        path:"/recover-password/new-password",
        label: "Recuperar contraseña - Nueva contraseña",
        component: <RecoverPasswordNewPassword/>,
    },
    {
        path:"/recover-password/success",
        label: "Recuperar contraseña - Recupero exitoso",
        component: <RecoverPasswordSuccess/>,
    },
]

export const adminAppRoutes = [
    {
        path:"/admin/app/movements",
        label: "Inversiones",
        icon: cashflow,
        show: true,
        component: <CashFlow/>,
    },
    {
        path:"/admin/app/movements/:id",
        label: "Editar inversión",
        icon: cashflow,
        show: false,
        component: <EditMovement/>,
    },
    {
        path:"/admin/app/movements/add",
        label: "Agregar inversión",
        icon: cashflow,
        show: false,
        component: <AddMovement/>,
    },
    {
        path:"/admin/app/users",
        label: "Usuarios",
        icon: users,
        show: true,
        component: <Users/>,
    },
    {
        path:"/admin/app/users/add",
        label: "Agregar usuario",
        component: <AddUser/>,
    },
    {
        path:"/admin/app/users/:id",
        label: "Editar usuario",
        component: <EditUser/>
    },
    {
        path:"/admin/app/profile",
        label: "Perfil",
        show: true,
        icon: profile,
        component: <AdminProfile/>
    },
]

export const usersAppRoutes = [
    {
        path:"/app/dashboard",
        label: "Dashboard",
        component: <Dashboard/>,
        icon:dashboard,
        show: true,
    },
    {
        path:"/app/activity",
        label: "Actividad",
        component: <Activity/>,
        icon: cashflow,
        show: true,
    },
    {
        path:"/app/documents",
        label: "Documentos",
        component: <Movements/>,
        icon:documents,
        show: true,
    },
    {
        absolutePath:BLOG_URL,
        label: "Noticias",
        icon:news,
        show: true,
    },
    {
        path:"/app/profile",
        label: "Mi perfil",
        component: <Profile/>,
        icon: profile,
        show: true,
    },
    {
        absolutePath:`https://wa.me/${CONTACT_NUMBER}`,
        label: "Contacto",
        icon: phone,
        show: true,
    },
]

export const appRoutes = [
    {
        path:"/app/dashboard",
        label: "Dashboard",
        component: <Dashboard/>,
        icon:dashboard,
        show: true,
    },
    {
        path:"/app/documents",
        label: "Documentos",
        component: <Movements/>,
        icon:cashflow,
        show: true,
    },
    {
        path:"/app/activity",
        label: "Actividad",
        component: <Activity/>,
        icon: cashflow,
        show: true,
    },
    {
        path:"/app/profile",
        label: "Mi perfil",
        component: <Profile/>,
        icon: profile,
        show: true,
    },
    //ADMIN
    {
        path:"/admin/app/users",
        label: "Usuarios",
        icon: users,
        show: true,
        component: <Users/>,
    },
    {
        path:"/admin/app/users/add",
        label: "Agregar usuario",
        component: <AddUser/>,
    },
    {
        path:"/admin/app/users/:id",
        label: "Editar usuario",
        component: <EditUser/>
    },
    {
        path:"/admin/app/movements",
        label: "Inversiones",
        icon: cashflow,
        show: false,
        component: <CashFlow/>,
    },
    {
        path:"/admin/app/movements/:id",
        label: "Editar movimiento",
        show: false,
        component: <EditMovement/>,
    },
    {
        path:"/admin/app/movements/add",
        label: "Agregar inversión",
        icon: cashflow,
        show: false,
        component: <AddMovement/>,
    },
    {
        path:"/admin/app/profile",
        label: "Perfil",
        show: true,
        icon: profile,
        component: <AdminProfile/>
    },
]