// Environmental Variables
// PRODUCTION
export const API_URL = "https://dolphin-app-j49g8.ondigitalocean.app/api";
// DEVELOP
// export const API_URL = "https://api-backend-develop-75q6d.ondigitalocean.app/api";


export const BLOG_URL = "http://blog.bruber.com.ar/wp-admin/?username=bruberuser&pass=bruberuser";
export const CONTACT_EMAIL = "administracion@bruber.com.ar";
export const CONTACT_NUMBER = "5491139402485";
export const MONEY_FORMAT = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency: 'ARS',
});