import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../data/variables';
import { getDate, getDateFromAPI } from '../utils/dateFormatter';
const AppContext = React.createContext();
const { Provider } = AppContext;



const AppProvider = ({ children }) => {

  const [toast, setToast] = useState({show: false, content:""});
  const [loading, setLoading] = useState(false);

  //Status
  const [fileStatus, setFileStatus] = useState("");
  const [generateReportStatus, setGenerateReportStatus] = useState("");
  const [changePasswordStatus, setChangePasswordStatus] = useState("");
  const [automaticUpdateStatus, setAutomaticUpdateStatus] = useState('');
  const [depositRequestStatus, setDepositRequestStatus] = useState('');
  const [restoreStatus, setRestoreStatus] = useState('');


  const token = localStorage.getItem('token');
  const [me, setMe] = useState(JSON.parse(localStorage.getItem('user')));
  const userInitialState = {
    firstName:"",
    lastName:"",
    phoneNumber:"",
    email:"",
    password:"",
    passwordConfirm:""
  }

  //Api Data Forms
  const [addUserData, setAddUserData] = useState(userInitialState);
  const [editUserData, setEditUserData] = useState(userInitialState);
  const [editProfileData, setEditProfileData] = useState(me);
  const [uploadFileData, setUploadFileData] = useState();
  const [editMovementData, setEditMovementData] = useState();
  const [addMovementData, setAddMovementData] = useState();
  const [changePassData, setChangePassData] = useState();
  const [selectedVersion, setSelectedVersion] = useState('');
  const [key, setKey] = useState('all');

  //Api data collectors
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [userInstruments, setUserInstruments] = useState([]);
  const [userWallet, setUserWallet] = useState([]);
  const [userWalletWithdrawal, setUserWalletWithdrawal] = useState([]);
  const [userInvestments, setUserInvestments] = useState([]);
  const [userActivity, setUserActivity] = useState([]);
  const [movements, setMovements] = useState([]);
  const [movement, setMovement] = useState({});
  const [userFiles, setUserFiles] = useState([]);
  const [lastVersions, setLastVersions] = useState([]);
  const [lastCalculation, setLastCalculation] = useState('');

  //Filters
  const [userInvestmentsFilters, setUserInvestmentsFilters] = useState({});
  const [userDocsFilters, setUserDocsFilters] = useState({});
  const [adminUsersFilters, setAdminUsersFilters] = useState({});
  const [movementsType, setMovementsType] = useState('');


  // ------ ADMIN -------

  // Usuarios
  const getUsers = (params) => {
    setLoading(true);
    axios
      .get(`${API_URL}/users`,{
        params:params
      })
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const getUser = (id) => {
    setLoading(true);
    axios
      .get(`${API_URL}/users/${id}`)
      .then((res) => {
        setEditUserData(res.data.data);
        setEditProfileData(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const addUser = (data, navigate) => {
    setLoading(true);
    axios
      .post(`${API_URL}/auth/register`, data)
      .then((res) => {
        setAddUserData({});
        navigate('/admin/app/users')
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const editUser = (data, navigate) => {
    setLoading(true);
    axios
      .patch(`${API_URL}/users/${data?.id}`, data)
      .then((res) => {
        setEditUserData({});
        navigate('/admin/app/users')
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const deleteUser = (id, navigate) => {
    setLoading(true);
    axios
      .delete(`${API_URL}/users/${id}`)
      .then((res) => {
        setLoading(false)
        getUsers();
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const uploadUserDocument = (data, navigate) => {
    setFileStatus("loading");
    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("userId", data.userId)
    axios
      .post(`${API_URL}/reports/invoice`, formData)
      .then((res) => {
        setEditUserData({});
        setFileStatus("success");
        setTimeout(() => {
          navigate('/admin/app/users');
          setFileStatus("");
        },2000)
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setFileStatus("");
      });
  }
  const generateUserReport = (userId, navigate) => {
    setGenerateReportStatus('loading');
    axios
    .post(`${API_URL}/reports/investments`, {userId: userId})
    .then((res) => {
      setGenerateReportStatus('success');
      setTimeout(() => {
        navigate('/admin/app/users');
        setGenerateReportStatus('');
      },2000)
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setGenerateReportStatus('');
    });
  }

  //Perfil
  const editProfile = (data, navigate) => {
    setLoading(true);
    axios
      .patch(`${API_URL}/users/me`, data)
      .then((res) => {
        localStorage.setItem('user', JSON.stringify(data));
        setMe(data);
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  }
  const changePassword = (data, navigate) => {
    setChangePasswordStatus('pending');
    axios
      .patch(`${API_URL}/users/me`, data)
      .then((res) => {
        setChangePasswordStatus('success');
        setTimeout(() => {
          navigate(0);
          setChangePasswordStatus('');
        }, 2000)
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setChangePasswordStatus('');
      });
  }
  const getMe = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/users/me`)
      .then((res) => {
        setMe(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };

  //Movimientos
  const getMovements = (type = '', params) => {
    setLoading(true);
    axios
      .get(`${API_URL}/investments${type == "/all" ? "" : type}`, {
        params:params
      })
      .then((res) => {
        setMovements(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  }
  const getMovement = (id) => {
    setLoading(true);
    axios
      .get(`${API_URL}/investments/${id}`)
      .then((res) => {
        setMovement(res.data);
        setEditMovementData({
          instrument:res.data.data.instrument,
          money: res.data.data.money ,
          commissions: res.data.data.commissions ,
          dateOfAdmission: new Date(res.data.data.dateOfAdmission).toISOString().slice(0, 10),
          returnDate: new Date(res.data.data.returnDate).toISOString().slice(0, 10) ,
          monthsInvested: res.data.data.monthsInvested ,
          annualRent: res.data.data.annualRent ,
          agreedPercentage: res.data.data.agreedPercentage ,
          monthlyRent: res.data.data.monthlyRent ,
          percentageYield: res.data.data.percentageYield ,
          momentaryPerformance: res.data.data.momentaryPerformance ,
          refundAmount: res.data.data.refundAmount,
          isAutomatic:res.data.data.isAutomatic
        });
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  }
  const addMovement = (data, navigate) => {
    setLoading(true);
    axios
      .post(`${API_URL}/investments/`, {
        ...data, 
        percentageYield:+data.percentageYield || 0,
        agreedPercentage:+data.agreedPercentage || 0,
        isAutomatic:!!data.isAutomatic,
        typeMovement: "deposit",
        userId:+data.userId
      })
      .then((res) => {
        setAddMovementData({});
        navigate('/admin/app/movements')
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const editMovement = (data, id, navigate) => {
    setLoading(true);
    axios
      .patch(`${API_URL}/investments/${id}`, {
        ...data, 
        percentageYield:+data.percentageYield,
        agreedPercentage:+data.agreedPercentage,
        isAutomatic:!!data.isAutomatic,
        annualRent: +data.annualRent,
        money: +data.money,
        refundAmount: +data.refundAmount,
        commissions: +data.commissions ,
        monthlyRent: +data.monthlyRent ,
        momentaryPerformance: +data.momentaryPerformance ,
        monthsInvested: +data.monthsInvested
      })
      .then((res) => {
        setEditMovementData({});
        navigate('/admin/app/movements')
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };
  const uploadReport = (data, navigate) => {
    setFileStatus('loading');
    const formData = new FormData();
    formData.append("file", data.file);
    axios
      .post(`${API_URL}/investments/upload-file`, formData)
      .then((res) => {
        setFileStatus('success');
        setTimeout(() => {
          navigate(0);
          setFileStatus('');
        }, 2000)
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setFileStatus('');
      });
  }
  const calculatePerformance = (navigate) => {
    setAutomaticUpdateStatus('loading');
    axios
      .post(`${API_URL}/jobs/calculate-performance`)
      .then((res) => {
        setAutomaticUpdateStatus('success');
        setTimeout(() => {
          navigate(0);
          setAutomaticUpdateStatus('');
        }, 2000)
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setAutomaticUpdateStatus('');
      });
  }
  const getLastVersions = () => {
    setLoading(true);
    axios
      .get(`${API_URL}/imports`)
      .then((res) => {
        setLastVersions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  }
  const restoreVersion = (version, navigate) => {
    setRestoreStatus('loading');
    axios
      .post(`${API_URL}/imports/${version}/restore`)
      .then((res) => {
        setRestoreStatus('success');
        setTimeout(() => {
          navigate(0);
          setRestoreStatus('');
        }, 2000)
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setRestoreStatus('');
      });
  }
  const getLastCalculationDate = () => {
    axios
      .get(`${API_URL}/jobs/last-execution`)
      .then((res) => {
        setLastCalculation(res.data.executedAt);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setAutomaticUpdateStatus('');
      });
  }
  const getInvestmentsFile = () => {
    axios
      .get(`${API_URL}/investments/download-csv`)
      .then((res) => {
        
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setAutomaticUpdateStatus('');
      });
  }


  // ----- USERS -----

  // Dashboard
  const getInstruments = () => {
    setLoading(true);
    axios
    .get(`${API_URL}/wallet/instruments`)
    .then((res) => {
      setUserInstruments(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };
  const getWalletData = () => {
    setLoading(true);
    axios
    .get(`${API_URL}/wallet`)
    .then((res) => {
      setUserWallet(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };
  const getWalletWithdrawals = () => {
    setLoading(true);
    axios
    .get(`${API_URL}/wallet/withdrawal`)
    .then((res) => {
      setUserWalletWithdrawal(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  }
  const getInvestments = (params) => {
    setLoading(true);
    axios
    .get(`${API_URL}/wallet/investments`, {
      params:params
    })
    .then((res) => {
      setUserInvestments(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };
  const getActivity = (params) => {
    setLoading(true);
    axios
    .get(`${API_URL}/wallet/movements`, {
      params:params
    })
    .then((res) => {
      setUserActivity(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };
  //Documents
  const getDocuments = (params) => {
    setLoading(true);
    axios
    .get(`${API_URL}/wallet/files`, {
      params:params
    })
    .then((res) => {
      setUserFiles(res.data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };

  // ----- CONTACT -----
  const sendDepositRequest = () => {
    setDepositRequestStatus("loading");
    axios
    .post(`${API_URL}/contact/deposit-request`)
    .then((res) => {
      setDepositRequestStatus("success");
      setTimeout(() => {
        setDepositRequestStatus('');
      }, 2000)
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
      setDepositRequestStatus('');
    });
  };


  useEffect(() => {
    setEditProfileData(JSON.parse(localStorage.getItem('user')));
  }, [token, user]);
  
  return (
    <Provider
      value={{
        loading, setLoading,
        toast, setToast,
        users, setUsers,
        user, setUser,
        getUsers, getUser, addUser, editUser, deleteUser, 
        uploadUserDocument, uploadFileData, setUploadFileData,uploadReport,
        fileStatus, setFileStatus,
        editProfile, editProfileData, setEditProfileData, me,
        changePassData, setChangePassData, changePassword,
        changePasswordStatus, setChangePasswordStatus,
        addUserData, setAddUserData,
        editUserData, setEditUserData,
        movements, setMovements, getMovements,
        getInstruments, getWalletData, getInvestments,
        userInstruments, userWallet, userInvestments,
        getDocuments, setUserFiles, userFiles,
        getMe,selectedVersion, setSelectedVersion,
        generateReportStatus, setGenerateReportStatus,
        generateUserReport,
        userInvestmentsFilters, setUserInvestmentsFilters,
        userDocsFilters, setUserDocsFilters,
        movementsType, setMovementsType,
        key, setKey, calculatePerformance,
        automaticUpdateStatus, setAutomaticUpdateStatus,
        restoreStatus, setRestoreStatus,restoreVersion, 
        getLastVersions, lastVersions, setLastVersions, 
        lastCalculation, setLastCalculation, getLastCalculationDate,
        movement, setMovement, getMovement,
        editMovementData, setEditMovementData, editMovement, 
        addMovementData, setAddMovementData, addMovement,
        depositRequestStatus, setDepositRequestStatus, sendDepositRequest,
        getActivity, userActivity, setUserActivity,
        adminUsersFilters, setAdminUsersFilters,
        getInvestmentsFile,
        userWalletWithdrawal, getWalletWithdrawals,
      }}>
      {children}
    </Provider>
  );
};
export { AppContext, AppProvider };
