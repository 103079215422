
import styles from './MovementsTemplate.module.css';
import Button from '../../../atoms/Button/Button';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import Tabs from '../../../molecules/Tabs/Tabs';
import { getDateTime } from '../../../../utils/dateFormatter';
import { useNavigate } from 'react-router';
import ContactButton from '../../../atoms/ContactButton/ContactButton';
import download from '../../../../assets/icons/download.svg';
import axios from 'axios';
import { API_URL } from '../../../../data/variables';
import Toast from '../../../atoms/Toast/Toast';
import ModalOptions from '../../../molecules/ModalOptions/ModalOptions';
import upload from '../../../../assets/icons/upload.svg';
import update from '../../../../assets/icons/update.svg';
import restore from '../../../../assets/icons/restore.svg';


export default function MovementsTemplate({apiData, buttonAction, calculatePerformance, lastVersions}){

  const { fileStatus, automaticUpdateStatus, restoreStatus, restoreVersion, lastCalculation, toast, setToast, selectedVersion, setSelectedVersion} = useContext(AppContext);
  
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showContent, setShowContent] = useState({
    options:true, content:false, status:false, option: 'all'
  });

  const handleDownload = () => {
    function downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    axios
      .get(`${API_URL}/investments/download-csv`, {responseType:"blob"})
      .then((res) => {
        let reader = new window.FileReader();
        reader.readAsDataURL(res.data); 
        reader.onload = function() {
        let imageDataUrl = reader.result;
        downloadURI(imageDataUrl, `Bruber - Listado de inversiones ${getDateTime(new Date)}.csv`);
        }
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
      });
  }


  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <h1>Inversiones</h1>
        <div className={styles.buttonContainer}>
          <Button
            text="Agregar inversión"
            style="primary"
            size="md"
            handleClick={() => navigate('/admin/app/movements/add')}
          />
          <Button
            text="Acciones"
            style="secondary"
            size="md"
            handleClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Tabs apiData={apiData}/>
      </div>
      <ContactButton
        img={download}
        onClick={() => handleDownload()}
      />
      <Toast
        toast={toast}
        setToast={setToast}
        content={toast?.content}
      />
      <ModalOptions
        show={showModal}
        setShow={setShowModal}
        showContent={showContent}
        setShowContent={setShowContent}
        options={[
          {
            id:"upload",
            icon:upload,
            title:"Subir inversiones",
          },
          {
            id:"update",
            icon:update,
            title:"Actualizar inversiones automáticas",
          },
          {
            id:"restore",
            icon:restore,
            title:"Restaurar inversiones",
          },
        ]}
      >
        {showContent?.option == "upload" &&
          <>
            <p><b>¿Estás seguro de subir un nuevo documento de inversiones?</b></p>
            <p style={{marginTop: 5, marginBottom: 26}}>Si hay algún error, solo podrás recuperar hasta 5 versiones anteriores.</p>
            <Button 
              text={fileStatus == "loading" ? 
              "Subiendo inversiones..." : 
              fileStatus == "success" ?
              "Inversiones subidas con éxito!" :
              "Subir inversiones"}
              style={fileStatus == "loading" ? 
              "disabled" : 
              fileStatus == "success" ?
              "success" : 
              "primary"}
              type="file"
              accept=".csv"
              size="sm"
              handleChange={(fileStatus == "loading" || fileStatus == "success") ? 
              undefined : 
              buttonAction}
            />
          </>
        }
        {showContent?.option == "update" &&
          <>
            <p><b>Última vez actualizado: {getDateTime(lastCalculation)}hs.</b></p>
            <p>¿Estás seguro de actualizar todas las inversiones automáticas?</p>
            <p style={{marginTop: 5, marginBottom: 26}}>Ésta acción no puede deshacerse.</p>
            <Button
              text={
              automaticUpdateStatus == "loading" ? 
              "Actualizando inversiones..." : 
              automaticUpdateStatus == "success" ?
              "Inversiones actualizadas con éxito!" :
              "Actualizar inversiones automáticas" }
              style={
                automaticUpdateStatus == "loading" ? 
                "disabled" : 
                automaticUpdateStatus == "success" ?
                "success" : 
                "primary" 
              }
              size="sm"
              handleClick={(automaticUpdateStatus == "loading" || automaticUpdateStatus == "success") ? 
              undefined : 
              calculatePerformance}
            />
          </>
        }
        {showContent?.option == "restore" &&
          <>
            <p style={{marginBottom:12}}><b>Seleccioná el la versión que querés restaurar:</b></p>
            {lastVersions?.length > 0 ?
              <>
                <div className={styles.optionContainer}>
                  {lastVersions?.map((version,i) => (
                    <div 
                      className={[styles.option, version.id == selectedVersion && styles.active].join(' ')}
                      onClick={() => setSelectedVersion(version.id)} 
                      key={i}
                    >
                      <p style={{margin:0}}><b>Archivo subido el </b>{getDateTime(version.createdAt)}</p>
                    </div>
                  ))}
                </div>
                <Button
                    text={
                    restoreStatus == "loading" ? 
                    "Restaurando versión..." : 
                    restoreStatus == "success" ?
                    "Versión restaurada con éxito!" :
                    "Restaurar versión" }
                    style={
                      restoreStatus == "loading" ? 
                      "disabled" : 
                      restoreStatus == "success" ?
                      "success" : 
                      "primary" 
                    }
                    size="sm"
                    handleClick={(restoreStatus == "loading" || restoreStatus == "success") ? 
                    undefined : 
                    () => restoreVersion(selectedVersion, navigate)}
                  />
              </> :
              <p>Todavía no hay ninguna versión para restaurar.</p>
            }
          </>
        }
      </ModalOptions>
    </div>
  );
}
  
  