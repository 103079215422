import styles from './FormGroup.module.css';

export default function FormGroup(props){

    const {label, placeholder, input, tag, id, setApiData, apiData, options, cols=12} = props;

    const Tag = tag;

    const handleChange = (e) => {
      setApiData({
        ...apiData,
        [e.target.id]: e.target.value
      })
    }

    return (
      <div className={[styles.container, cols == 6 && styles.halfColumn].join(' ')}>
        <label className={styles.label} htmlFor={id}>
            {label}
        </label>
        {tag == "select" ? 
          <select 
            id={id}
            onChange={(e) => handleChange(e)}
            className={styles.field}
            value={apiData && apiData[id]}
          >
            <option value="" id="" disabled selected>
              Seleccionar...
            </option>
            {options?.map((option,i) => (
              <option key={i} value={option.value} id={option.id} disabled={option.disabled || false} selected={option.selected || false}>
                {option.label}
              </option>
            ))}
          </select> :
          <Tag
            placeholder={placeholder}
            type={input}
            id={id}
            value={apiData && apiData[id]}
            onChange={(e) => handleChange(e)}
            className={styles.field}
          />
        }
      </div>
    );
  }
  
  