import Button from '../../../atoms/Button/Button';
import styles from './UsersTemplate.module.css';
import waves from '../../../../assets/bk/template-waves.svg';
import CardUser from '../../../molecules/CardUser/CardUser';
import { useNavigate } from 'react-router';
import Filter from '../../../organisms/Filter/Filter';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../contexts/AppContext';
import { adminUsersFiltersData } from '../../../../data/filters';
import filter from '../../../../assets/icons/filter.svg'
import Paginator from '../../../atoms/Paginator/Paginator';

export default function UsersTemplate({users, pagination}){

  const navigate = useNavigate();
  const [showFilters, setShowFilters] = useState(false);
  const {getUsers, adminUsersFilters, setAdminUsersFilters} = useContext(AppContext);


  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div>
          <h2>Hola Admin!</h2>
          <h1>Usuarios</h1>
        </div>
        <Button 
          text="Agregar usuario" 
          style="primary" 
          size="md"
          handleClick={() => navigate('/admin/app/users/add')}
        />
      </div> 
      <div className={styles.cardsContainer}>
        <Button
          icon={filter}
          style="naked"
          text="Filtrar"
          size="sm"
          handleClick={() => setShowFilters(true)}
        />
        {users?.length == 0 ?
          <p className={styles.noResults}>No hay usuarios que cumplan con el criterio de búsqueda.</p>:
          users?.map((user,i) => (
            <CardUser key={i} {...user}/>
          ))
        }
        <div className={styles.paginationContainer}>
          <Paginator
            {...pagination}
            action={getUsers}
          />
        </div>
      </div>
      <Filter
        show={showFilters}
        setShow={setShowFilters}
        getApiData={getUsers}
        filters={adminUsersFilters}
        setFilters={setAdminUsersFilters}
        formFields={adminUsersFiltersData?.formFields}
        button={adminUsersFiltersData?.button}
      />
    </div>
  );
}
  
  