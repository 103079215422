
export const signInForm = {
    title:"Iniciar sesión",
    formFields: [
        {
            label:"Email",
            input: "text",
            tag: "input",
            placeholder: "joedoe@example.com",
            id:"email"
        },
        {
            label:"Contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"password"
        },
    ],
    button:{
        text:"Iniciar sesión",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const signUpForm = {
    title:"Registrarse",
    formFields: [
        {
            label:"Nombre",
            input: "text",
            tag: "input",
            placeholder: "Joe",
            id:"firstName"
        },
        {
            label:"Apellido",
            input: "text",
            tag: "input",
            placeholder: "Doe",
            id:"lastName"
        },
        {
            label:"Teléfono",
            input: "text",
            tag: "input",
            placeholder: "+549113111111111",
            id:"phoneNumber"
        },
        {
            label:"Email",
            input: "email",
            tag: "input",
            placeholder: "joedoe@example.com",
            id:"email"
        },
        {
            label:"Contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"password"
        },
        {
            label:"Repetir contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"passwordpasswordConfirm"
        },
    ],
    button:{
        text:"Registrarse",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const recoverPasswordEmailForm = {
    title:"Recuperar contraseña",
    subtitle:"Ingresá el email con el que te registrarte donde te enviaremos un código de recuperación. No olvides chequear el spam.",
    formFields: [
        {
            label:"Email",
            input: "email",
            tag: "input",
            placeholder: "joedoe@example.com",
            id:"email"
        },
    ],
    button:{
        text:"Enviar código",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const recoverPasswordCodeForm = {
    title:"Recuperar contraseña",
    subtitle:"Ingresá el código recibido al email ingresado anteriormente.",
    formFields: [
        {
            label:"Código",
            input: "text",
            tag: "input",
            placeholder: "xxxxx",
            id:"token"
        },
    ],
    button:{
        text:"Verificar código",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const recoverPasswordNewPasswordForm = {
    title:"Recuperar contraseña",
    subtitle:"Ingresá una nueva contraseña",
    formFields: [
        {
            label:"Contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"password"
        },
        {
            label:"Repetir contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"passwordConfirm"
        },
    ],
    button:{
        text:"Cambiar contraseña",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const profileDataForm = {
    title:"Información personal",
    formFields: [
        {
            label:"Nombre",
            input: "text",
            tag: "input",
            placeholder: "Joe",
            id:"firstName"
        },
        {
            label:"Apellido",
            input: "text",
            tag: "input",
            placeholder: "Doe",
            id:"lastName"
        },
        {
            label:"Teléfono",
            input: "text",
            tag: "input",
            placeholder: "+5491131000000",
            id:"phoneNumber"
        },
        {
            label:"Email",
            input: "text",
            tag: "input",
            placeholder: "joedoe@example.com",
            id:"email"
        },
    ],
    button:{
        text:"Guardar cambios",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const profileChangePassForm = {
    title:"Cambiar contraseña",
    formFields: [
        {
            label:"Nueva contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"password"
        },
        {
            label:"Repetir nueva contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"passwordConfirm"
        },
    ],
    button:{
        text:"Cambiar contraseña",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const addUserForm = {
    formFields: [
        {
            label:"Nombre",
            input: "text",
            tag: "input",
            placeholder: "Joe",
            id:"firstName"
        },
        {
            label:"Apellido",
            input: "text",
            tag: "input",
            placeholder: "Doe",
            id:"lastName"
        },
        {
            label:"Teléfono",
            input: "text",
            tag: "input",
            placeholder: "+5491131000000",
            id:"phoneNumber"
        },
        {
            label:"Email",
            input: "text",
            tag: "input",
            placeholder: "joedoe@example.com",
            id:"email"
        },
        {
            label:"Contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"password"
        },
        {
            label:"Repetir contraseña",
            input: "password",
            tag: "input",
            placeholder: "******",
            id:"passwordConfirm"
        },
    ],
    button:{
        text:"Agregar usuario",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}
export const editUserForm = {
    formFields: [
        {
            label:"Nombre",
            input: "text",
            tag: "input",
            placeholder: "Joe",
            id:"firstName"
        },
        {
            label:"Apellido",
            input: "text",
            tag: "input",
            placeholder: "Doe",
            id:"lastName"
        },
        {
            label:"Teléfono",
            input: "text",
            tag: "input",
            placeholder: "+5491131000000",
            id:"phoneNumber"
        },
        {
            label:"Email",
            input: "text",
            tag: "input",
            placeholder: "joedoe@example.com",
            id:"email"
        },
    ],
    button:{
        text:"Editar usuario",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}

export const editAutomaticMovementForm = {
    formFields: [
        {
            label:"Instrumento",
            input: "text",
            tag: "input",
            placeholder: "Ej: Criptomoneda",
            id:"instrument",
            cols:6,
        },
        {
            label:"Capital",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"money",
            cols:6,
        },
        {
            label:"Fee",
            input: "text",
            tag: "input",
            placeholder: "120",
            id:"commissions",
            cols:6,
        },
        {
            label:"Meses invertidos",
            input: "text",
            tag: "input",
            placeholder: "7",
            id:"monthsInvested",
            cols:6,
        },
        {
            label:"Fecha de ingreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2023",
            id:"dateOfAdmission",
            cols:6,
        },
        {
            label:"Fecha de egreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2024",
            id:"returnDate",
            cols:6,
        },
        {
            label:"Renta pactada (pesos)",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"annualRent",
            cols:6,
        },
        {
            label:"Renta pactada (porcentaje)",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"agreedPercentage",
            cols:6,
        },
        {
            label:"Renta mensual (pesos)",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"monthlyRent",
            cols:6,
        },
        {
            label:"Renta mensual (porcentaje)",
            input: "text",
            tag: "input",
            placeholder: "4.05",
            id:"percentageYield",
            cols:6,
        },
        {
            label:"Renta acumulada (pesos)",
            input: "text",
            tag: "input",
            placeholder: "",
            id:"momentaryPerformance",
            cols:6,
        },
        {
            label:"Devolución",
            input: "text",
            tag: "input",
            placeholder: "20000",
            id:"refundAmount",
            cols:6,
        },
    ],
    button:{
        text:"Editar movimiento",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}
export const editManualMovementForm = {
    formFields: [
        {
            label:"Instrumento",
            input: "text",
            tag: "input",
            placeholder: "Ej: Criptomoneda",
            id:"instrument",
            cols:6,
        },
        {
            label:"Capital",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"money",
            cols:6,
        },
        {
            label:"Fee",
            input: "text",
            tag: "input",
            placeholder: "120",
            id:"commissions",
            cols:6,
        },
        {
            label:"Meses invertidos",
            input: "text",
            tag: "input",
            placeholder: "7",
            id:"monthsInvested",
            cols:6,
        },
        {
            label:"Fecha de ingreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2023",
            id:"dateOfAdmission",
            cols:6,
        },
        {
            label:"Fecha de egreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2024",
            id:"returnDate",
            cols:6,
        },
        {
            label:"Renta acumulada (pesos)",
            input: "text",
            tag: "input",
            placeholder: "",
            id:"momentaryPerformance",
            cols:6,
        },
    ],
    button:{
        text:"Editar movimiento",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}
export const addAutomaticMovementForm = {
    formFields: [
        {
            label:"Usuario",
            tag: "select",
            id:"userId",
            cols:6,
        },
        {
            label:"Instrumento",
            input: "text",
            tag: "input",
            placeholder: "Ej: Criptomoneda",
            id:"instrument",
            cols:6,
        },
        {
            label:"Capital",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"money",
            cols:6,
        },
        {
            label:"Fee",
            input: "text",
            tag: "input",
            placeholder: "120",
            id:"commissions",
            cols:6,
        },
        {
            label:"Fecha de ingreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2023",
            id:"dateOfAdmission",
            cols:6,
        },
        {
            label:"Fecha de egreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2024",
            id:"returnDate",
            cols:6,
        },
        {
            label:"Tipo de inversión",
            tag: "select",
            placeholder: "7",
            id:"isAutomatic",
            cols:6,
        },
        {
            label:"Meses invertidos",
            input: "text",
            tag: "input",
            placeholder: "7",
            id:"monthsInvested",
            cols:6,
        },
        {
            label:"Renta pactada (pesos)",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"annualRent",
            cols:6,
        },
        {
            label:"Renta pactada (porcentaje)",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"agreedPercentage",
            cols:6,
        },
        {
            label:"Renta mensual (pesos)",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"monthlyRent",
            cols:6,
        },
        {
            label:"Renta mensual (porcentaje)",
            input: "text",
            tag: "input",
            placeholder: "4.05",
            id:"percentageYield",
            cols:6,
        },
        {
            label:"Renta acumulada (pesos)",
            input: "text",
            tag: "input",
            placeholder: "",
            id:"momentaryPerformance",
            cols:6,
        },
        {
            label:"Devolución",
            input: "text",
            tag: "input",
            placeholder: "20000",
            id:"refundAmount",
            cols:6,
        },
    ],
    button:{
        text:"Agregar inversión",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}
export const addManualMovementForm = {
    formFields: [
        {
            label:"Usuario",
            tag: "select",
            id:"userId",
            cols:6,
        },
        {
            label:"Instrumento",
            input: "text",
            tag: "input",
            placeholder: "Ej: Criptomoneda",
            id:"instrument",
            cols:6,
        },
        {
            label:"Capital",
            input: "text",
            tag: "input",
            placeholder: "12000",
            id:"money",
            cols:6,
        },
        {
            label:"Fee",
            input: "text",
            tag: "input",
            placeholder: "120",
            id:"commissions",
            cols:6,
        },
        {
            label:"Fecha de ingreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2023",
            id:"dateOfAdmission",
            cols:6,
        },
        {
            label:"Fecha de egreso",
            input: "date",
            tag: "input",
            placeholder: "01/01/2024",
            id:"returnDate",
            cols:6,
        },
        {
            label:"Tipo de inversión",
            tag: "select",
            placeholder: "7",
            id:"isAutomatic",
            cols:6,
        },
        {
            label:"Meses invertidos",
            input: "text",
            tag: "input",
            placeholder: "7",
            id:"monthsInvested",
            cols:6,
        },
        {
            label:"Renta acumulada (pesos)",
            input: "text",
            tag: "input",
            placeholder: "",
            id:"momentaryPerformance",
            cols:6,
        },
    ],
    button:{
        text:"Agregar inversión",
        style:"primary",
        fullWidth: true,
        size:"sm"
    }
}