import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../data/variables';
const AuthContext = React.createContext();
const { Provider } = AuthContext;



const AuthProvider = ({ children }) => {

  const [userInfo, setUserInfo] = useState({});
  const [toast, setToast] = useState({show: false, content:""});
  const [loading, setLoading] = useState(false);

  const token = localStorage.getItem('token');
  const user = localStorage.getItem('user');

  const [signInData, setSignInData] = useState({});
  const [recoverPasswordEmailData, setRecoverPasswordEmailData] = useState({});
  const [recoverPasswordCodeData, setRecoverPasswordCodeData] = useState({});
  const [recoverPasswordNewPasswordData, setRecoverPasswordNewPasswordData] = useState({});


  // Iniciar sesion
  const signIn = (data, navigate) => {
    setLoading(true);
    axios
      .post(`${API_URL}/auth/login`, data)
      .then((res) => {
        localStorage.setItem('token', res.data.accessToken);
        localStorage.setItem('user', JSON.stringify(res.data.userData));
        setUserInfo(res.userData);
        navigate(res.data.userData.role.id == 1 ? '/admin/app/users' : '/app/dashboard');
        setLoading(false);
      })
      .catch((err) => {
        setToast({show:true, content:err?.response?.data?.message});
        setLoading(false);
      });
  };

  // Recuperar contraseña
  const recoverPasswordVerifyEmail = (data, navigate) =>{
    setLoading(true);
    axios
    .post(`${API_URL}/auth/forgot-password`, data)
    .then((res) => {
      navigate('/recover-password/code');
      setRecoverPasswordCodeData(data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };
  const recoverPasswordResendCode = (data) =>{
    axios
    .post(`${API_URL}/auth/forgot-password`, data)
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
    });
  };
  const recoverPasswordValidateCode = (data, navigate) =>{
    setLoading(true);
    axios
    .post(`${API_URL}/auth/validate-token-password`, data)
    .then((res) => {
      navigate('/recover-password/new-password');
      setRecoverPasswordNewPasswordData(data);
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };
  const recoverPasswordChangePassword = (data, navigate) =>{
    setLoading(true);
    axios
    .post(`${API_URL}/auth/change-password`, {...data, code: data?.token})
    .then((res) => {
      navigate('/recover-password/success');
      setRecoverPasswordEmailData({});
      setRecoverPasswordCodeData({});
      setRecoverPasswordNewPasswordData({});
      setLoading(false);
    })
    .catch((err) => {
      setToast({show:true, content:err?.response?.data?.message});
      setLoading(false);
    });
  };

  // Logout
  const signOut = (navigate) => {
    const token = localStorage.getItem('token');
    setLoading(true);
    if (token) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      navigate('/sign-in');
      setLoading(false);
    }else{
      setLoading(false);
      navigate('/sign-in');
    }
  };

  useEffect(() => {
    setUserInfo(JSON.parse(localStorage.getItem('user')));
  }, [token, user]);
  
  return (
    <Provider
      value={{
        loading, setLoading,
        toast, setToast,
        signInData, setSignInData,
        recoverPasswordEmailData, setRecoverPasswordEmailData,
        recoverPasswordCodeData, setRecoverPasswordCodeData,
        recoverPasswordNewPasswordData, setRecoverPasswordNewPasswordData,
        recoverPasswordVerifyEmail,
        recoverPasswordResendCode,
        recoverPasswordValidateCode,
        recoverPasswordChangePassword,
        signIn, signOut,
        userInfo, setUserInfo
      }}>
      {children}
    </Provider>
  );
};
export { AuthContext, AuthProvider };
