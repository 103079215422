
import styles from './MovementsFormTemplate.module.css';
import back from '../../../../assets/icons/back.svg';
import Form from '../../../organisms/Form/Form';
import { useNavigate } from 'react-router';
import Toast from '../../../atoms/Toast/Toast';
import { useContext } from 'react';
import { AppContext } from '../../../../contexts/AppContext';

export default function MovementsFormTemplate(props){

  const navigate = useNavigate();
  const {title, action, setApiData, apiData, formData, id, users, disabled} = props;
  const {toast, setToast} = useContext(AppContext);

  const formFields = formData.formFields?.map(field => {
      if(field.id == 'userId'){
        return{
          ...field, 
          options:users?.map(user => {
            return {
              id: "userId",
              label: `${user?.firstName} ${user?.lastName}`,
              value: user?.id
            }
          })
        }
      }else if(field.id == 'isAutomatic'){
        return {
          ...field, 
          options:[
            {
              id: "isAutomatic",
              label: "Automática",
              value: true
            },
            {
              id: "isAutomatic",
              label: "Manual",
              value: false
            }
          ]
        }
      }else{
        return {...field}
      }
  })

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.headingContainer}>
          <div 
            className={styles.backButton}
            onClick={() => navigate('/admin/app/movements')}
          >
            <img 
              src={back} 
              className={styles.back}
              alt="back"
            />
            <p>Atrás</p>
          </div>
          <h1>{title}</h1>
        </div>
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.content}>
          <Form 
            formFields={formFields}
            button={{
              ...formData.button,
              handleClick: id ? () => action(apiData, id, navigate) : () => action(apiData, navigate),
              disabled: disabled
            }}
            setApiData={setApiData}
            apiData={apiData}
          />
        </div>
      </div>
      <Toast
        toast={toast}
        setToast={setToast}
        content={toast?.content}
      />
    </div>
  );
}
  
  