import styles from './Menu.module.css';
import { useContext, useEffect, useState } from 'react';
import logo from '../../../assets/logos/horizontal-logo-white.svg'
import { useLocation, useNavigate } from 'react-router';
import logout from '../../../assets/icons/logout.svg';
import menu from '../../../assets/icons/menu.svg';
import { AuthContext } from '../../../contexts/AuthContext';

export default function Menu({routes}){

  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {signOut} = useContext(AuthContext);

  useEffect(() => {
    window.outerWidth > 576 && setShowMenu(true)
  },[])

  return (
    <header className={styles.container}>
        <img src={logo}  className={styles.logo} alt="logo"/>
        <img 
          src={menu}  
          className={styles.logoMenu} 
          alt="logo"
          onClick={() => setShowMenu(!showMenu)}
        />
        {showMenu &&
          <div className={styles.routesContainer}>
            {routes?.map((route,i) => (
              route.show && 
              (route.absolutePath ?
              <div className={styles.routeContainer}key={i} >
                <img src={route.icon} alt="icon"/>
                <a 
                  href={route.absolutePath}
                  target='_blank'
                >
                  {route.label}
                </a>
              </div>  :
              <div 
                key={i} 
                className={[
                  styles.routeContainer, 
                  (location?.pathname == route.path) && styles.active
                ].join(' ')}
                onClick={() => navigate(route.path)}
              >
                <img src={route.icon} alt="icon"/>
                <p>{route.label}</p>
              </div>
            )))}
            <div 
              className={styles.routeContainer}
              onClick={() => signOut(navigate)}
            >
              <img src={logout} alt="icon"/>
              <p>Logout</p>
            </div>
          </div>
        }
    </header>
  );
  }
  
  