import styles from './CardMovement.module.css';
import dollar from '../../../assets/icons/dollar.svg';
import triangle from '../../../assets/icons/triangle.svg';
import { getDate } from '../../../utils/dateFormatter';
import { MONEY_FORMAT } from '../../../data/variables';

export default function CardMovement(props){

  const {agreedPercentage, totalInvested, monthlyRent, momentaryPerformance, percentageYield, dateOfAdmission, returnDate, instrument, money, monthsInvested} = props;

    return (
      <div className={styles.card}>
        <div className={styles.firstItem}>
          <div className={[styles.icon, styles[instrument == "Cash" ? "primary" : instrument == "Crypto" ? "secondary" : "tertiary"]].join(' ')}>
            <img src={dollar} alt="icon"/>
          </div>
          <div className={styles.item}>
          <h5 className={styles.title}>
            Instrumento
          </h5>
            <p className={styles.value}>{instrument}</p>
          </div>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Capital</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+money)}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Rendimiento acumulado ($)</h5>
          <p className={styles.value}>{(momentaryPerformance == "0.00" ||!momentaryPerformance || momentaryPerformance == "NaN") ? "-" : `${MONEY_FORMAT.format(+momentaryPerformance)}`}</p>
        </div>
        {/* <div className={styles.item}>
          <h5 className={styles.title}>Rendimiento mensual ($)</h5>
          <p className={styles.value}>{(monthlyRent == "0.00" || !monthlyRent) ? "-" : `${MONEY_FORMAT.format(+monthlyRent)}`}</p>
        </div> */}
        <div className={styles.item}>
          <h5 className={styles.title}>Rendimiento mensual (%)</h5>
          <p className={styles.value}>{(percentageYield == "0.00" || !percentageYield || percentageYield == "NaN")? "-" :`${percentageYield}%`}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Existencias</h5>
          <p className={styles.value}>{MONEY_FORMAT.format(+money + +momentaryPerformance)}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Meses invertidos</h5>
          <p className={styles.value}>{monthsInvested || 0}</p>
        </div>
        {/* <div className={styles.item}>
          <h5 className={styles.title}>Rendimiento total</h5>
          <p className={styles.value}>{agreedPercentage}%</p>
        </div> */}
        <div className={styles.item}>
          <h5 className={styles.title}>Fecha inicio</h5>
          <p className={styles.value}>{getDate(dateOfAdmission)}</p>
        </div>
        <div className={styles.item}>
          <h5 className={styles.title}>Fecha renovacion</h5>
          <p className={styles.value}>
            {getDate(returnDate) || "-"}
            {((new Date(returnDate) - new Date()) < 2.592e+9 && 
              (new Date(returnDate) - new Date()) > 0
            )
             && " ⚠️"}
          </p>
        </div>
      </div>
    );
  }
  
  