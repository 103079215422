import { useContext, useEffect } from "react";
import Loader from "../../components/atoms/Loader/Loader";
import AppTemplate from "../../components/templates/AppTemplate/AppTemplate";
import ProfileTemplate from "../../components/templates/users/ProfileTemplate/ProfileTemplate";
import { adminAppRoutes } from "../../data/routes";
import { AppContext } from "../../contexts/AppContext";


export default function AdminProfile(){

    const {editProfile, editProfileData, setEditProfileData, changePassword, setChangePassData, changePassData, loading, getMe} = useContext(AppContext);

    useEffect(() => {
      getMe();
    },[])


    return (
      <main>
        {loading ? 
          <Loader/> :
          <AppTemplate routes={adminAppRoutes}>
          <ProfileTemplate
            editProfile={editProfile}
            editProfileData={editProfileData}
            setEditProfileData={setEditProfileData}
            changePassword={changePassword}
            changePassData={changePassData}
            setChangePassData={setChangePassData}
          />
        </AppTemplate>
        }
      </main>
    );
}
  